import * as React from 'react';
import Modal from '@mui/material/Modal';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import './AddUserModal.styles.css';
import { apiErrorHandler } from '../../utils/errorHandler';
import { openSnackBar } from '../../redux/actions/snackbar.actions';
import { useDispatch } from 'react-redux';
import CustomToolTip from '../../components/custom-tooltip/CustomTooltip.component';
import { checkPassword } from '../../utils/helperFn';
import { getApiUrl } from '../../utils/apiUrls';
import instance from '../../utils/axios';
import { useSelector } from 'react-redux';
import CustomButtonLoader from '../../components/global/CustomButtonLoader.component';

const accountTypeOp = [
    { value: 'ADMIN', label: 'ADMIN' },
    { value: 'SME', label: 'SME' }
];

const initial_state = {
    accountType: {
        value: { label: '', value: '' },
        isRequired: true,
        msg: null
    },
    firstName: {
        value: '',
        isRequired: true,
        msg: null
    },
    lastName: {
        value: '',
        isRequired: true,
        msg: null
    },
    email: {
        value: '',
        isRequired: true,
        msg: null
    },
    password: {
        value: '',
        isRequired: true,
        msg: null
    },
    passwordVisible: false,
    passwordConfirm: {
        value: '',
        isRequired: true,
        msg: null
    },
    passwordConfirmVisible: false,
    passwordConfirm: {
        value: '',
        isRequired: true,
        msg: null
    },
    easyRate: {
        value: '',
        isRequired: false,
        msg: null
    },
    mediumRate: {
        value: '',
        isRequired: false,
        msg: null
    },
    difficultRate: {
        value: '',
        isRequired: false,
        msg: null
    },
    easyRateReview: {
        value: '',
        isRequired: false,
        msg: null
    },
    mediumRateReview: {
        value: '',
        isRequired: false,
        msg: null
    },
    difficultRateReview: {
        value: '',
        isRequired: false,
        msg: null
    },
    contact_number: {
        value: '',
        isRequired: true,
        msg: null
    },
    teamName: {
        value: { teamName: '', teamId: '' },
        isRequired: true,
        msg: null
    },
    validOptions: {
        requiredFields: ['accountType', 'firstName', 'lastName', 'email', 'password', 'passwordConfirm', 'easyRate', 'mediumRate', 'difficultRate', 'contact_number', 'easyRateReview', 'mediumRateReview', 'difficultRateReview', 'teamName'],
        errorFields: [],
    },
};

const reset_state = {
    accountType: {
        value: { label: '', value: '' },
        isRequired: true,
        msg: null
    },
    firstName: {
        value: '',
        isRequired: true,
        msg: null
    },
    lastName: {
        value: '',
        isRequired: true,
        msg: null
    },
    email: {
        value: '',
        isRequired: true,
        msg: null
    },
    password: {
        value: '',
        isRequired: true,
        msg: null
    },
    passwordVisible: false,
    passwordConfirm: {
        value: '',
        isRequired: true,
        msg: null
    },
    passwordConfirmVisible: false,
    passwordConfirm: {
        value: '',
        isRequired: true,
        msg: null
    },
    easyRate: {
        value: '',
        isRequired: false,
        msg: null
    },
    mediumRate: {
        value: '',
        isRequired: false,
        msg: null
    },
    difficultRate: {
        value: '',
        isRequired: false,
        msg: null
    },
    easyRateReview: {
        value: '',
        isRequired: false,
        msg: null
    },
    mediumRateReview: {
        value: '',
        isRequired: false,
        msg: null
    },
    difficultRateReview: {
        value: '',
        isRequired: false,
        msg: null
    },
    contact_number: {
        value: '',
        isRequired: true,
        msg: null
    },
    teamName: {
        value: { teamName: '', teamId: '' },
        isRequired: true,
        msg: null
    },
    validOptions: {
        requiredFields: ['accountType', 'firstName', 'lastName', 'email', 'password', 'passwordConfirm', 'easyRate', 'mediumRate', 'difficultRate', 'contact_number', 'easyRateReview', 'mediumRateReview', 'difficultRateReview', 'teamName'],
        errorFields: [],
    },
};

export default function AddUserModal({ modalStatus, handleModalToggle, t, getAllUsers, editUser }) {
    const dispatch = useDispatch();
    const [formVal, setFormVal] = React.useState(initial_state);
    const [teamName, setTeamName] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(false);
    const user = useSelector(state => state.user);

    React.useEffect(() => {
        if (editUser !== null) {
            const editUserTeamName = teamName.find((team) => team.teamId === editUser.teamId);
            setFormVal({
                accountType: {
                    value: editUser.accountType.toLowerCase() === 'admin' ? accountTypeOp[0] : accountTypeOp[1],
                    isRequired: true,
                    msg: null
                },
                firstName: {
                    value: editUser.firstName,
                    isRequired: true,
                    msg: null
                },
                lastName: {
                    value: editUser.lastName,
                    isRequired: true,
                    msg: null
                },
                email: {
                    value: editUser.emailId,
                    isRequired: true,
                    msg: null
                },
                password: {
                    value: '',
                    isRequired: true,
                    msg: null
                },
                passwordVisible: false,
                passwordConfirm: {
                    value: '',
                    isRequired: true,
                    msg: null
                },
                passwordConfirmVisible: false,
                passwordConfirm: {
                    value: '',
                    isRequired: true,
                    msg: null
                },
                easyRate: {
                    value: !!editUser.rates ? editUser.rates.creation.easy : '',
                    isRequired: false,
                    msg: null
                },
                mediumRate: {
                    value: !!editUser.rates ? editUser.rates.creation.medium : '',
                    isRequired: false,
                    msg: null
                },
                difficultRate: {
                    value: !!editUser.rates ? editUser.rates.creation.difficult : '',
                    isRequired: false,
                    msg: null
                },
                easyRateReview: {
                    value: !!editUser.rates ? editUser.rates.review.easy : '',
                    isRequired: false,
                    msg: null
                },
                mediumRateReview: {
                    value: !!editUser.rates ? editUser.rates.review.medium : '',
                    isRequired: false,
                    msg: null
                },
                difficultRateReview: {
                    value: !!editUser.rates ? editUser.rates.review.difficult : '',
                    isRequired: false,
                    msg: null
                },
                contact_number: {
                    value: editUser.contactNumber,
                    isRequired: true,
                    msg: null
                },
                teamName: {
                    value: { teamName: editUserTeamName ? editUserTeamName.teamName : '', teamId: editUserTeamName ? editUserTeamName.teamId : '' },
                    isRequired: true,
                    msg: null
                },
                validOptions: {
                    requiredFields: ['accountType', 'firstName', 'lastName', 'email', 'easyRate', 'mediumRate', 'difficultRate', 'contact_number', 'easyRateReview', 'mediumRateReview', 'difficultRateReview', 'teamName'],
                    errorFields: [],
                },
            });
        } else {
            setFormVal(reset_state);
        }
    }, [editUser])

    const handleFieldChange = (e, fieldName) => {
        setFormVal({
            ...formVal,
            [fieldName]: {
                ...formVal[fieldName],
                value: e.target.value,
            }
        })
    }

    const handleSelectChange = (e, fieldName) => {
            setFormVal({
                ...formVal,
                [fieldName]: {
                    ...formVal[fieldName],
                    value: e.target.value
                }
            })
        }

    const handlePassVisiblity = (fieldName) => {
        setFormVal({
            ...formVal,
            [fieldName]: !formVal[fieldName]
        })
    }

    const getReqMsg = (fieldName) => {
        if (fieldName === 'firstName' || fieldName === 'lastName') {
            return t('input_field_required');
        } else if (fieldName === 'email') {
            return t('email_required');
        } else if (fieldName === 'password' || fieldName === 'passwordConfirm') {
            return t('sign_up_password_required');
        } else {
            return t('field_required');
        }
    }

    const handleEditUser = async () => {
        try {
            const dataToSend = {
                userId: editUser.userId,
                firstName: formVal.firstName.value,
                lastName: formVal.lastName.value,
                contactNumber: formVal.contact_number.value,
                rates: {
                    creation: { "easy": formVal.easyRate.value, "medium": formVal.mediumRate.value, "difficult": formVal.difficultRate.value },
                    review: { "easy": formVal.easyRateReview.value, "medium": formVal.mediumRateReview.value, "difficult": formVal.difficultRateReview.value },
                },
                worksOn: null,
                identification: null,
                teamId: formVal.teamName.value.teamName !== '' ? formVal.teamName.value.teamId  : editUser.teamId
            }

            const res = await instance.post(`${getApiUrl("editUser")}`, dataToSend);
            if (res.data) {
                dispatch(openSnackBar({
                    type: 'success',
                    msg: 'Data saved successfully'
                }))
                getAllUsers();
                handleModalToggle();
            }
        } catch (error) {
            console.log('getUserDetails err', error);
            const errObj = apiErrorHandler(error);
            dispatch(openSnackBar({
                msg: t(errObj.statusText),
                type: 'error'
            }))
        }
    }

    const addUser = (data) => async (dispatch, getState) => {
        try {
            setIsLoading(true);

            const createUserData = await instance.post(getApiUrl('createUser'), data);

            if (createUserData.data.status) {
                setIsLoading(false);
                setFormVal(reset_state);
                dispatch(openSnackBar({
                    msg: t(createUserData.data.message),
                    type: 'success'
                }));
                handleModalToggle();
                getAllUsers();
            } else {
                throw Error(createUserData.data);
            }
        } catch (err) {
            setIsLoading(false);
            const errObj = apiErrorHandler(err);

            dispatch(openSnackBar({
                msg: t(errObj.statusText),
                type: 'error'
            }))
        }
    }

    const encProcess = async () => {
        try {
            setIsLoading(true);
            const getKey = await instance.get(getApiUrl('getSk'));
            if (!!getKey.data) {
                setIsLoading(false);
                const JSEncrypt = window.JSEncrypt;

                //RSA Public Key
                const rsaKey = getKey.data.key;
                const passToEncrypt = formVal.password.value;
                const confirmPassToEncrypt = formVal.passwordConfirm.value;
                const skid = getKey.data.id;

                //encrypt AES key with RSA public key
                var rsaEncrypt = new JSEncrypt({ default_key_size: 2048 });
                rsaEncrypt.setPublicKey(rsaKey);
                var rsaEncryptedAesKey = rsaEncrypt.encrypt(passToEncrypt.toString());
                var rsaEncryptedAesKey2 = rsaEncrypt.encrypt(confirmPassToEncrypt.toString());

                var encryptedTransaction = {
                    skid,
                    firstName: formVal.firstName.value,
                    lastName: formVal.lastName.value,
                    emailId: formVal.email.value,
                    role: formVal.accountType.value.value,
                    contactNumber: formVal.contact_number.value,
                    password: rsaEncryptedAesKey.toString(),
                    confirmPassword: rsaEncryptedAesKey2.toString(),
                    rates: {
                        creation: { "easy": formVal.easyRate.value, "medium": formVal.mediumRate.value, "difficult": formVal.difficultRate.value },
                        review: { "easy": formVal.easyRateReview.value, "medium": formVal.mediumRateReview.value, "difficult": formVal.difficultRateReview.value },
                    },
                    worksOn: null,
                    identification: null,
                    teamId: formVal.teamName.value.teamId
                };

                dispatch(addUser(encryptedTransaction));

            }

        } catch (error) {
            setIsLoading(false);
            const errObj = apiErrorHandler(error);

            dispatch(openSnackBar({
                msg: t(errObj.statusText),
                type: 'error'
            }))
        }
    }

    const handleValidation = () => {
        //Check required fields are filled
        let currentFormVal = formVal;
        let currentUser = formVal.accountType.value.value;
        formVal.validOptions.requiredFields.forEach(el => {
            if (el !== 'accountType' && el !== 'teamName' && currentUser === 'SME' && currentFormVal[el].value.trim() === '' && currentFormVal[el].isRequired) {
                currentFormVal[el].msg = getReqMsg(el);
            } else {
                if (el === 'accountType' && currentFormVal[el].value.value === '') {
                    currentFormVal[el].msg = getReqMsg(el);
                    return;
                }
                if (el === 'teamName' && user.teamId === 1 && currentFormVal[el].value.teamId === '') {
                    currentFormVal[el].msg = getReqMsg(el);
                    return;
                }
                if (el === 'firstName' && currentUser === 'SME' && currentFormVal[el].value !== '') {
                    currentFormVal[el].msg = null;
                    return
                }
                //Email Validation
                if (el === 'email') {
                    // eslint-disable-next-line
                    const regex = /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
                    if(!regex.test(currentFormVal.email.value)) {
                        currentFormVal.email.msg =  t("valid_email_address");
                        return;
                    } else {
                        currentFormVal.email.msg  = null;
                    }
                    

                    if (currentUser === 'ADMIN' && user.teamId !== 1) {
                        const domainRegex = /^[a-zA-Z0-9._%+-]+@mercer\.com$/;
                        if(!domainRegex.test(currentFormVal.email.value)) {
                            currentFormVal.email.msg =  t("valid_email_domain");
                            return;
                        } else {
                            currentFormVal.email.msg = null;
                        }
                    }
                }

                //Password Validation
                if (el === 'password' && currentUser === 'SME') {
                    const isValid = checkPassword(currentFormVal.password.value);
                    currentFormVal.password.msg = !isValid ? t("valid_password") : null;
                    return;
                }
                if (el === 'passwordConfirm' && currentUser === 'SME') {
                    const isValid = checkPassword(currentFormVal.passwordConfirm.value);
                    currentFormVal.passwordConfirm.msg = !isValid ? t("valid_password") : null;
                }

                //Match Passwords
                if (el === 'passwordConfirm' && currentUser === 'SME') {
                    currentFormVal.passwordConfirm.msg = currentFormVal.password.value !== currentFormVal.passwordConfirm.value ? t('passwords_not_match') : null;
                    return;
                }
                //Rates Validation
                if (currentUser === 'SME' && ['easyRate', 'mediumRate', 'difficultRate', 'easyRateReview', 'mediumRateReview', 'difficultRateReview'].includes(el)) {
                    //Check if number
                    if (currentFormVal[el].value !== '' && isNaN(currentFormVal[el].value)) {
                        currentFormVal[el].msg = t('rate_format_msg');
                        return;
                    }

                    //Check if greater than 0
                    if (currentFormVal[el].value !== '' && Number(currentFormVal[el].value) <= 0) {
                        currentFormVal[el].msg = t('rate_greater_than_zero_msg');
                        return;
                    }
                }


                currentFormVal[el].msg = null;
            }
        });

        //Check if form is valid
        let isValidFlag = 0;
        currentFormVal.validOptions.requiredFields.forEach((el) => {
            if (formVal[el].msg === null) {
                isValidFlag++;
            }
        });

        if (isValidFlag === currentFormVal.validOptions.requiredFields.length) {
            //Api Call
            if (!isLoading) {
                editUser === null ? encProcess() : handleEditUser()
            }
        } else {
            setFormVal({
                ...formVal,
                ...currentFormVal,
            })
        }

    }

    const getAllTeams = () => {
        instance.get(`${getApiUrl('getAllTeams')}`)
            .then(function (response) {
                if (response.data) {
                    setTeamName(response.data);
                }
            })
            .catch(function (error) {
                console.log(error);
            })
    }

    React.useEffect(() => {
        user.teamId === 1 && getAllTeams();
    }, [])

    const handleFormSubmit = (e) => {
        e.preventDefault();
        console.log('formVal', formVal)
        handleValidation();
    }

    const loadSelectMenuItem = (list, label, value) => list.map(el => <MenuItem value={el} key={el[value]}>{el[label]}</MenuItem>);
    const modalStyle = { minWidth: '400px', width: 'auto' };
    const inputStyle = { width: '300px' };

    const handleClose = () => {
        setFormVal(initial_state);
        handleModalToggle();
    }

    return (
        <div>
            <Modal open={modalStatus} onClose={handleClose} disableAutoFocus={true} >
                <div className='cm-create-task-form-container bg-white my-3' style={modalStyle}>
                    <div className='cm-create-task-form-header py-3 px-4 d-flex align-items-center justify-content-between'>
                        <h4 className='text-blue-800 my-0'>Fill Details to Add User</h4>
                        <FontAwesomeIcon icon={regular("times")} className="p-2 cm-pointer" onClick={handleClose} />
                    </div>
                    <form onSubmit={handleFormSubmit} className="cm-create-task-form px-4 pb-4">
                        <div className="row d-flex flex-row flex-nowrap">
                            <div style={inputStyle}>
                                <div className="form-group mb-3" >
                                    <label htmlFor="account-type-select" className='pb-2 text-blue-gray-700 cm-xs-txt fw-medium'>Account Type</label>
                                    <Select
                                        id="account-type-select"
                                        value={formVal.accountType?.value}
                                        onChange={val => handleSelectChange(val, 'accountType')}
                                        className="cm-sm-txt fw-medium"
                                        disabled={editUser !== null}
                                    >
                                        {loadSelectMenuItem(accountTypeOp, 'label', 'value')}
                                    </Select>
                                    {formVal.accountType?.msg && <span className='cm-xs-txt text-danger fw-medium pt-2'>{formVal.accountType.msg}</span>}
                                </div>
                            </div>
                            {!!editUser && user.teamId === 1 &&
                                <div style={inputStyle}>
                                    <div className="form-group mb-3">
                                        <label htmlFor="account-type-select" className='pb-2 text-blue-gray-700 cm-xs-txt fw-medium'>Team Name</label>
                                        <Select
                                            id="Team-Name-select"
                                            value={formVal.teamName?.value}
                                            onChange={val => handleSelectChange(val, 'teamName')}
                                            className="cm-sm-txt fw-medium"
                                            renderValue={(selected) =>{
                                                return selected.teamName;
                                            }}
                                        >
                                            {loadSelectMenuItem(teamName, 'teamName', 'teamId')}
                                        </Select>
                                        {formVal.teamName?.msg && <span className='cm-xs-txt text-danger fw-medium pt-2'>{formVal.teamName.msg}</span>}
                                    </div>
                                </div>
                            }

                            {
                                editUser === null && formVal.accountType?.value?.value &&
                                (
                                    formVal.accountType?.value?.value === "SME" ?
                                        <>
                                            <div style={inputStyle}>
                                                <div className="form-group mb-3">
                                                    <label htmlFor="firstName" className='pb-2 text-blue-gray-700 cm-xs-txt fw-medium'>{t("first_name")}*</label>
                                                    <input type="text" className="form-control text-blue-800 fw-medium cm-sm-txt" id="firstName" value={formVal.firstName.value} placeholder={t("signup_fname_placeholder")} onChange={(e) => handleFieldChange(e, 'firstName')} />
                                                    {formVal.firstName.msg !== null && <span className='cm-xs-txt text-danger fw-medium pt-2'>{formVal.firstName.msg}</span>}
                                                </div>
                                            </div>
                                            <div style={inputStyle}>
                                                <div className="form-group mb-3">
                                                    <label htmlFor="lastName" className='pb-2 text-blue-gray-700 cm-xs-txt fw-medium'>{t("last_name")}*</label>
                                                    <input type="text" className="form-control text-blue-800 fw-medium cm-sm-txt" id="lastName" value={formVal.lastName.value} placeholder={t("signup_lname_placeholder")} onChange={(e) => handleFieldChange(e, 'lastName')} />
                                                    {formVal.lastName.msg !== null && <span className='cm-xs-txt text-danger fw-medium pt-2'>{formVal.lastName.msg}</span>}
                                                </div>
                                            </div>
                                        </>
                                        :
                                        <>
                                            <div style={inputStyle}>
                                                <div className="form-group mb-3">
                                                    <label htmlFor="email" className='pb-2 text-blue-gray-700 cm-xs-txt fw-medium'>{t("login_email_label")}*</label>
                                                    <input type="email" className="form-control text-blue-800 fw-medium cm-sm-txt" disabled={!!editUser} id="email" value={formVal.email.value} placeholder={t("login_email_placeholder")} onChange={(e) => handleFieldChange(e, 'email')} />
                                                    {formVal.email.msg !== null && <span className='cm-xs-txt text-danger fw-medium pt-2'>{formVal.email.msg}</span>}
                                                </div>
                                            </div>
                                            {user.teamId === 1 &&
                                                <div style={inputStyle}>
                                                    <div className="form-group mb-3">
                                                        <label htmlFor="account-type-select" className='pb-2 text-blue-gray-700 cm-xs-txt fw-medium'>Team Name*</label>
                                                        <Select
                                                            id="Team-Name-select"
                                                            value={formVal.teamName?.value}
                                                            onChange={val => handleSelectChange(val, 'teamName')}
                                                            className="cm-sm-txt fw-medium"
                                                        >
                                                            {loadSelectMenuItem(teamName, 'teamName', 'teamId')}
                                                        </Select>
                                                        {formVal.teamName?.msg && <span className='cm-xs-txt text-danger fw-medium pt-2'>{formVal.teamName.msg}</span>}
                                                    </div>
                                                </div>
                                            }
                                        </>
                                )
                            }
                        </div>

                        {
                            editUser === null && formVal.accountType?.value?.value === "SME" &&
                            <div className="row d-flex flex-row flex-nowrap">
                                <div style={inputStyle}>
                                    <div className="form-group mb-3">
                                        <label htmlFor="email" className='pb-2 text-blue-gray-700 cm-xs-txt fw-medium'>{t("login_email_label")}*</label>
                                        <input type="email" className="form-control text-blue-800 fw-medium cm-sm-txt" disabled={!!editUser} id="email" value={formVal.email.value} placeholder={t("login_email_placeholder")} onChange={(e) => handleFieldChange(e, 'email')} />
                                        {formVal.email.msg !== null && <span className='cm-xs-txt text-danger fw-medium pt-2'>{formVal.email.msg}</span>}
                                    </div>
                                </div>
                                <div style={inputStyle}>
                                    <div className="form-group mb-3">
                                        <label htmlFor="password" className='pb-2 text-blue-gray-700 cm-xs-txt fw-medium'>{t("set_a_password")}*</label>
                                        <div className="cm-icon-field position-relative cm-double-icon-field">
                                            <input type={formVal?.passwordVisible ? 'text' : 'password'} className="form-control text-blue-800 fw-medium cm-sm-txt" id="password" value={formVal?.password.value} placeholder={t("password")} onChange={(e) => handleFieldChange(e, 'password')} />
                                            <FontAwesomeIcon icon={formVal?.passwordVisible ? regular('eye-slash') : regular('eye')} className={`form-text text-blue-gray-500 position-absolute cm-pass-visiblility-changer`} onClick={() => handlePassVisiblity('passwordVisible')} />
                                            <CustomToolTip
                                                title={
                                                    <>
                                                        <span className='my-0 cm-sm-txt fw-medium'>{t("your_password_should_be")}</span>
                                                        <ul className='cm-tooltip-pass-info mt-3 mb-0 ps-3 cm-sm-txt fw-medium'>
                                                            <li>{t("pass_point_1")}</li>
                                                            <li>{t("pass_point_2")}</li>
                                                            <li>{t("pass_point_3")}</li>
                                                            <li>{t("pass_point_4")}</li>
                                                        </ul>
                                                    </>
                                                }
                                            >
                                                <FontAwesomeIcon icon={regular("question-circle")} className={`form-text text-blue-gray-500 position-absolute cm-pass-info-icon`} />
                                            </CustomToolTip>
                                        </div>

                                        {formVal?.password.msg !== null && <span className='cm-xs-txt text-danger fw-medium pt-2'>{formVal?.password.msg}</span>}
                                    </div>
                                </div>
                                <div style={inputStyle}>
                                    <div className="form-group mb-3">
                                        <label htmlFor="password-confirm" className='pb-2 text-blue-gray-700 cm-xs-txt fw-medium'>{t("confirm_password")}*</label>
                                        <div className="cm-icon-field position-relative cm-double-icon-field">
                                            <input type={formVal.passwordConfirmVisible ? 'text' : 'password'} className="form-control text-blue-800 fw-medium cm-sm-txt" id="password-confirm" value={formVal.passwordConfirm.value} placeholder={t("password")} onChange={(e) => handleFieldChange(e, 'passwordConfirm')} />
                                            <FontAwesomeIcon icon={formVal.passwordConfirmVisible ? regular('eye-slash') : regular('eye')} className={`form-text text-blue-gray-500 position-absolute cm-pass-visiblility-changer`} onClick={() => handlePassVisiblity('passwordConfirmVisible')} />
                                            <CustomToolTip
                                                title={
                                                    <>
                                                        <span className='my-0 cm-sm-txt fw-medium'>{t("your_password_should_be")}</span>
                                                        <ul className='cm-tooltip-pass-info mt-3 mb-0 ps-3 cm-sm-txt fw-medium'>
                                                            <li>{t("pass_point_1")}</li>
                                                            <li>{t("pass_point_2")}</li>
                                                            <li>{t("pass_point_3")}</li>
                                                            <li>{t("pass_point_4")}</li>
                                                        </ul>
                                                    </>
                                                }
                                            >
                                                <FontAwesomeIcon icon={regular("question-circle")} className={`form-text text-blue-gray-500 position-absolute cm-pass-info-icon`} />
                                            </CustomToolTip>
                                        </div>
                                        {formVal.passwordConfirm.msg !== null && <span className='cm-xs-txt text-danger fw-medium pt-2'>{formVal.passwordConfirm.msg}</span>}
                                    </div>
                                </div>

                            </div>
                        }

                        {editUser === null && formVal.accountType?.value?.value === "SME" &&
                            <div className='row d-flex flex-row flex-nowrap'>
                                <div style={inputStyle}>
                                    <div className="form-group mb-3">
                                        <label htmlFor="contact_number" className='pb-2 text-blue-gray-700 cm-xs-txt fw-medium'>{t("contact_number")}*</label>
                                        <input type="tel" className="form-control text-blue-800 fw-medium cm-sm-txt" id="contact_number" value={formVal.contact_number.value} placeholder={t("contact_number_placeholder")} onChange={(e) => handleFieldChange(e, 'contact_number')} />
                                        {formVal.contact_number.msg !== null && <span className='cm-xs-txt text-danger fw-medium pt-2'>{formVal.contact_number.msg}</span>}
                                    </div>
                                </div>

                                {user.teamId === 1 && editUser === null && formVal.accountType.value.value === "SME" &&
                                    <div style={inputStyle}>
                                        <div className="form-group mb-3">
                                            <label htmlFor="account-type-select" className='pb-2 text-blue-gray-700 cm-xs-txt fw-medium'>Team Name*</label>
                                            <Select
                                                id="Team-Name-select"
                                                value={formVal.teamName?.value}
                                                onChange={val => handleSelectChange(val, 'teamName')}
                                                className="cm-sm-txt fw-medium"
                                                disabled={editUser !== null}
                                            >
                                                {loadSelectMenuItem(teamName, 'teamName', 'teamId')}
                                            </Select>
                                            {formVal.teamName?.msg && <span className='cm-xs-txt text-danger fw-medium pt-2'>{formVal.teamName.msg}</span>}
                                        </div>
                                    </div>
                                }
                            </div>
                        }


                        {formVal.accountType.value.value === 'SME' &&
                            <>
                                <h5 className='my-0 py-3 text-blue-700'>Rates for Creation</h5>

                                <div className="row d-flex flex-row flex-nowrap">
                                    <div style={inputStyle}>
                                        <div className="form-group mb-3">
                                            <label htmlFor="easyRate" className='pb-2 text-blue-gray-700 cm-xs-txt fw-medium'>{t("easy")}</label>
                                            <input type="number" className="form-control text-blue-800 fw-medium cm-sm-txt" id="easyRate" onWheel={(e) => e.target.blur()} value={formVal.easyRate.value} placeholder={t("enter_rate")} onChange={(e) => handleFieldChange(e, 'easyRate')} />
                                            {formVal.easyRate.msg !== null && <span className='cm-xs-txt text-danger fw-medium pt-2'>{formVal.easyRate.msg}</span>}
                                        </div>
                                    </div>
                                    <div style={inputStyle}>
                                        <div className="form-group mb-3">
                                            <label htmlFor="mediumRate" className='pb-2 text-blue-gray-700 cm-xs-txt fw-medium'>{t("medium")}</label>
                                            <input type="number" min="0" className="form-control text-blue-800 fw-medium cm-sm-txt" id="mediumRate" onWheel={(e) => e.target.blur()} value={formVal.mediumRate.value} placeholder={t("enter_rate")} onChange={(e) => handleFieldChange(e, 'mediumRate')} />
                                            {formVal.mediumRate.msg !== null && <span className='cm-xs-txt text-danger fw-medium pt-2'>{formVal.mediumRate.msg}</span>}
                                        </div>
                                    </div>
                                    <div style={inputStyle}>
                                        <div className="form-group mb-3">
                                            <label htmlFor="difficultRate" className='pb-2 text-blue-gray-700 cm-xs-txt fw-medium'>{t("difficult")}</label>
                                            <input type="number" className="form-control text-blue-800 fw-medium cm-sm-txt" id="difficultRate" onWheel={(e) => e.target.blur()} value={formVal.difficultRate.value} placeholder={t("enter_rate")} onChange={(e) => handleFieldChange(e, 'difficultRate')} />
                                            {formVal.difficultRate.msg !== null && <span className='cm-xs-txt text-danger fw-medium pt-2'>{formVal.difficultRate.msg}</span>}
                                        </div>
                                    </div>
                                </div>

                                <h5 className='my-0 py-3 text-blue-700'>Rates for Review</h5>

                                <div className="row d-flex flex-row flex-nowrap">
                                    <div style={inputStyle}>
                                        <div className="form-group mb-3">
                                            <label htmlFor="easyRateReview" className='pb-2 text-blue-gray-700 cm-xs-txt fw-medium'>{t("easy")}</label>
                                            <input type="number" className="form-control text-blue-800 fw-medium cm-sm-txt" id="easyRateReview" onWheel={(e) => e.target.blur()} value={formVal.easyRateReview.value} placeholder={t("enter_rate")} onChange={(e) => handleFieldChange(e, 'easyRateReview')} />
                                            {formVal.easyRateReview.msg !== null && <span className='cm-xs-txt text-danger fw-medium pt-2'>{formVal.easyRateReview.msg}</span>}
                                        </div>
                                    </div>
                                    <div style={inputStyle}>
                                        <div className="form-group mb-3">
                                            <label htmlFor="mediumRateReview" className='pb-2 text-blue-gray-700 cm-xs-txt fw-medium'>{t("medium")}</label>
                                            <input type="number" min="0" className="form-control text-blue-800 fw-medium cm-sm-txt" id="mediumRateReview" onWheel={(e) => e.target.blur()} value={formVal.mediumRateReview.value} placeholder={t("enter_rate")} onChange={(e) => handleFieldChange(e, 'mediumRateReview')} />
                                            {formVal.mediumRateReview.msg !== null && <span className='cm-xs-txt text-danger fw-medium pt-2'>{formVal.mediumRateReview.msg}</span>}
                                        </div>
                                    </div>
                                    <div style={inputStyle}>
                                        <div className="form-group mb-3">
                                            <label htmlFor="difficultRateReview" className='pb-2 text-blue-gray-700 cm-xs-txt fw-medium'>{t("difficult")}</label>
                                            <input type="number" className="form-control text-blue-800 fw-medium cm-sm-txt" id="difficultRateReview" onWheel={(e) => e.target.blur()} value={formVal.difficultRateReview.value} placeholder={t("enter_rate")} onChange={(e) => handleFieldChange(e, 'difficultRateReview')} />
                                            {formVal.difficultRateReview.msg !== null && <span className='cm-xs-txt text-danger fw-medium pt-2'>{formVal.difficultRateReview.msg}</span>}
                                        </div>
                                    </div>
                                </div>
                            </>
                        }

                        <div className="cm-form-btn-group mt-4 pt-2 d-flex align-items-center">
                            <CustomButtonLoader
                                showLoadingState={isLoading}
                                colorTheme="blue"
                                icon={regular('angle-right')}
                                reverseIconDirection={false}
                                buttonLabel={!!editUser ? t("edit_user") : t("create_an_account")}
                                buttonStyle={"px-12 py-10 cm-mar-left-icon"}
                                handleOnClick={handleFormSubmit}
                            />
                        </div>
                    </form>
                </div>
            </Modal>
        </div>
    );
}